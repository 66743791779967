<style lang="scss">
  .po-password-strength.risky {
    color: #f95e68;
  }

  .po-password-strength.guessable {
    color: #fb964d;
  }

  .po-password-strength.weak {
    color: #fdd244;
  }

  .po-password-strength.safe {
    color: #b0dc53;
  }

  .po-password-strength.secure {
    color: #35cc62;
  }
</style>
<template>
  <div
    class="py-16 bg-grey-light min-h-full"
  >
    <v-inner-header
      :hide-close="true"
    />
    <vue-headful
      title="Reset password | Octoa"
    />
    <div class="max-w-xs mt-10 text-center mx-auto">
      <router-link
        class="mb-3 block"
        to="/"
      />
      <h1 class="mt-16 mb-8 text-2xl lg:text-3xl">
        Reset Password
      </h1>
    </div>
    <form
      class="max-w-sm mx-auto bg-white rounded mt-8 px-8 pt-6 pb-8 mb-4"
      @submit.prevent="send"
    >
      <div class="mb-4">
        <label
          class="block text-grey-darker text-sm mb-2"
          for="username"
        >
          Email address
        </label>
        <input
          id="email"
          ref="email"
          v-model="form.email"
          class="w-full"
          type="text"
          placeholder=""
        >
      </div>
      <div class="mb-4">
        <label
          class="block text-grey-darker text-sm mb-2"
          for="password"
        >
          New Password <span class="text-grey-semi-light">(At least one number and 8+ characters)</span>
        </label>
        <input
          id="password"
          ref="password"
          v-model="form.password"
          class="w-full"
          type="password"
          placeholder=""
          @input="checkPassword"
        >
        <password-meter
          :password="form.password"
          @score="onPasswordScore"
        />
        <div
          class="mt-1 po-password-strength"
          :class="passwordStrength"
        >
          {{ passwordScore }}
        </div>
      </div>
      <div class="mb-4">
        <label
          class="block text-grey-darker text-sm mb-2"
          for="password_confirmation"
        >
          Confirm new password
        </label>
        <input
          id="password_confirmation"
          ref="password_confirmation"
          v-model="form.password_confirmation"
          class="w-full"
          type="password"
          placeholder=""
        >
      </div>
      <button
        class="green-btn w-full rounded"
        type="submit"
      >
        Reset password
      </button>
    </form>
    <div class="mt-4 max-w-md text-center mx-auto">
      <router-link
        to="/login"
        class="inline-block underline align-baseline text-sm text-grey-darker hover:text-blue-darker"
      >
        Back to login
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .title {
    margin: 70px 0;
    padding: 0;
    display: block;
    text-align: center;
  }
</style>

<script>
import passwordMeter from 'vue-simple-password-meter'

export default {
  name: 'ResetPassword',
  components: { passwordMeter },
  data() {
    return {
      form: {
        email:'',
        password: '',
        password_confirmation: '',
        token: '',
      },
      loading: false,
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      passwordScore: null,
      passwordStrength: null,
    }
  },
  mounted() {
    this.form.token = this.$route.query.token
    this.$refs.email.focus()
  },
  methods: {
    onPasswordScore({ score, strength }) {
      this.passwordStrength = strength
      if(score < 2){
        this.passwordScore = 'Weak'
      }
      if(score > 1 && score < 4){
        this.passwordScore = 'Average'
      }
      if(score == 4){
        this.passwordScore = 'Strong'
      }
    },
    checkPassword() {
      this.password_length = this.form.password.length
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/

      if (this.password_length > 7) {
        this.contains_eight_characters = true
      } else {
        this.contains_eight_characters = false
      }

      this.contains_alphabet = /[A-Za-z]/.test(this.form.password)
      this.contains_number = /\d/.test(this.form.password)
      this.contains_uppercase = /[A-Z]/.test(this.form.password)
      this.contains_special_character = format.test(this.form.password)

      if (this.contains_alphabet === true && this.contains_eight_characters === true && this.contains_number === true) {
            this.valid_password = true
      } else {
        this.valid_password = false
      }
    },
    async send() {
      this.loading = true
      this.$toasted.clear()

      if(!this.valid_password){
        this.$toasted.global.general_error({
          message : 'Please check if password contains 8+ characters and at least one number.'
        })
        return false
      }

      try {
        const { data } = await this.$api.get('user').resetPassword(this.form)

        this.$toasted.global.general_success({
          message : 'Your password has been updated.'
        })
        setTimeout(() => this.$router.replace({ name: 'Login' }), 1500)

      }catch(e) {
        console.log(e)
        this.$toasted.global.general_error({
          message : e.response.data.errors.password
        })
      }

      this.loading = false
    }
  }
}
</script>
